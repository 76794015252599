import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import PostList from "../components/Blog/PostList";
import { PageHeader } from "../components/PageHeader";
import { decodeEntities } from "../utils/helpers";
import Layout from "../components/Layout";

const Author = (props) => {
  const { data, location } = props;
  const {
    wordpressWpUsers,
    categoryFilter,
    wordpressWpSettings,
    siteSettings,
  } = data;
  const { wordpressUrl, date_format } = wordpressWpSettings;
  const { authorsPosts, name } = wordpressWpUsers;
  const { showAuthor, customCss } = siteSettings.options;
  if (!authorsPosts) {
    return null;
  }
  const totalCount = (authorsPosts && authorsPosts.length) || 0;
  const { title: siteTitle } = wordpressWpSettings;
  const title = `${totalCount} post${totalCount === 1 ? "" : "s"} by ${name}`;

  // The `authored_wordpress__POST` returns a simple array instead of an array
  // of edges / nodes. We therefore need to convert the array here.
  const posts = authorsPosts.map((post) => ({
    node: post,
  }));

  return (
    <Layout location={location} wordpressUrl={wordpressUrl} theme={"dark"}>
      <SEO
        title={`${decodeEntities(name)} | ${decodeEntities(siteTitle)}`}
        location={location}
      />
      <PageHeader
        headerTitle={name}
        headerSubTitle="Author"
        location={location}
      />
      <PostList
        posts={posts}
        title={title}
        categoryFilter={categoryFilter}
        siteMetadata={wordpressWpSettings}
        pathPrefix={`/${wordpressWpSettings.blogSlug}/`}
        showAuthor={showAuthor}
        dateFormat={date_format}
      />
    </Layout>
  );
};

export default Author;

export const pageQuery = graphql`
  query AuthorPage($id: String!) {
    siteSettings: wordpressAcfOptions {
      options {
        showAuthor
        customCss
      }
    }
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      siteUrl
      date_format
    }
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          path
          id
        }
      }
    }
    wordpressWpUsers(id: { eq: $id }) {
      name
      authorsPosts: authored_wordpress__POST {
        ...PostListFields
      }
    }
  }
`;
